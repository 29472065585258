import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core';
import { ConfigError, ConfigProvider, loadConfig } from './config';
import ErrorBoundary from './ErrorBoundary';
import * as gtag from './gtag';
import * as sentry from './sentry';
import theme from './theme';
import {
  createUpdateManager,
  register as registerServiceWorker,
  UpdateManager,
} from './serviceWorker';
import { init as initSecurity, SecurityProvider } from './auth';
import { reportError } from './logging';
import App from './components/App';
import { ErrorPage } from './components/Errors';
import { PreferencesProvider } from './data/user-preferences';
import { ms } from './util/timers';
import TagManager from 'react-gtm-module';

import './index.css';
import { RetryPeriodProvider } from './data/hooks/useRetryPeriod';

const updateManager = createUpdateManager({
  checkIntervalMs: ms({ minutes: 5 }),
});

const handleClickRetry = () => {
  // Sending the user to PUBLIC_URL will get rid of any extra parts to the
  // URL that may be causing a problem. For example '#state=invalid&...'
  // hash parameters returned from the OIDC issuer.
  window.location.replace(process.env.PUBLIC_URL + '/');
};

const main = async () => {
  const config = await loadConfig();
  const gtmId = config.GTM_CONTAINER_ID;
  sentry.init(config);
  gtag.init(config);
  const gtmArgs = {
    gtmId: gtmId,
  };

  // GTM initialization
  TagManager.initialize(gtmArgs);

  const { authRequired, user, instance, error } = await initSecurity(config);

  ReactDOM.render(
    <ErrorBoundary onClickRetry={handleClickRetry}>
      <ConfigProvider config={config}>
        <UpdateManager.Provider value={updateManager}>
          <SecurityProvider
            authRequired={authRequired}
            initialUser={user}
            instance={instance}
            error={error}
          >
            <PreferencesProvider>
              <ThemeProvider theme={theme}>
                <RetryPeriodProvider>
                  <App />
                </RetryPeriodProvider>
              </ThemeProvider>
            </PreferencesProvider>
          </SecurityProvider>
        </UpdateManager.Provider>
      </ConfigProvider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
};

main().catch(error => {
  reportError(error);

  let diagnosticInfo;
  if (error instanceof ConfigError) {
    // A config error will have occurred before Sentry got initialized, so there
    // will be no error report. Display extra info to help resolve issues.
    diagnosticInfo =
      error.config === undefined
        ? 'Missing configuration'
        : 'Invalid configuration';
  } else if (!sentry.isEnabled()) {
    diagnosticInfo = 'This problem could not be reported';
  }

  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <ErrorPage
        title="App was unable to load"
        message={diagnosticInfo}
        onClickRetry={handleClickRetry}
        fullPageHeight
        version={process.env.REACT_APP_VERSION}
      />
    </ThemeProvider>,
    document.getElementById('root')
  );
});
registerServiceWorker();
