import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import { withStyles, makeStyles } from '@material-ui/core';
import { formatKpi } from '../../../format';

const styles = {
  root: {
    '.customizeTooltip': {
      backgroundColor: '#fff',
    },
    '& .recharts-surface': {
      width: 'auto',
      height: 'auto',
      '& .recharts-layer': {
        '& defs': { display: 'none' },
      },
    },

    '& .recharts-wrapper': {
      position: 'absolute',
      '& .recharts-cartesian-grid': {
        '& .recharts-cartesian-grid-horizontal': {
          // display: 'none'
          '& line': {
            display: 'none',
          },
          '& line:last-child': {
            display: 'block',
          },
        },
      },

      '& .xAxis': {
        '& .recharts-cartesian-axis-tick-line:only-child': {
          display: 'none',
        },
      },
    },
  },
};

const useStyles = ({ strokeA, strokeB }) => {
  return makeStyles(() => ({
    customizeTooltip: {
      backgroundColor: '#FFF',
      minWidth: '85px',
      fontWeight: 'bold',
      padding: '5px',
      borderRadius: '6px',
    },
    currentYr: {
      color: strokeA,
      fontSize: '13px',
    },
    priorYr: {
      color: strokeB,
      fontSize: '13px',
    },
    periodName: {
      color: '#000',
      fontSize: '11px',
    },
  }));
};
const CustomizedTooltip = ({
  label,
  payload,
  active,
  chartType,
  strokeA,
  strokeB,
  useLLY,
}) => {
  const styles = useStyles({ strokeA, strokeB })();

  if (!active || !label || payload?.length === 0 || payload === null)
    return null;
  const currentYrValue = payload[chartType]?.payload?.formattedValue;
  const compare = payload[chartType]?.payload?.compare;
  const priorYrValue = compare
    ? payload[chartType]?.payload?.formattedCmpValue
    : payload[chartType]?.payload?.formattedPyValue;

  return (
    <div className={styles.customizeTooltip}>
      {currentYrValue !== undefined && (
        <p className={styles.currentYr}>
          {`${compare ? currentYrValue : 'CY:' + currentYrValue}`}
        </p>
      )}
      <p className={styles.priorYr}>{`${compare
          ? priorYrValue
          : useLLY
            ? 'LLY:' + priorYrValue
            : 'LY:' + priorYrValue
        }`}</p>
      {payload[chartType]?.payload?.period_name && (
        <p
          className={styles.periodName}
        >{`${payload[chartType]?.payload?.period_name}`}</p>
      )}
      {payload[chartType]?.payload?.date && (
        <p
          style={{ fontSize: '11px' }}
        >{`${payload[chartType]?.payload?.date}`}</p>
      )}
    </div>
  );
};

const axisDataFormater = (value, currency, format) => {
  return value && value >= 0
    ? formatKpi(value, {
      format: format,
      currency: currency,
      maximumFractionDigits: null,
    })
    : 0;
};

class CustomizedxAxisTick extends React.PureComponent {
  render() {
    const { x, y, payload } = this.props;
    const label = payload.value;
    // using below line for hiding some lables
    //const label = period ==='fiscal_quarter'?( length>0 && (payload?.value.includes('Wk 01') || payload.index === length-1)? payload.value : '') : payload.value ;
    return (
      label !== '' && (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={5}
            textAnchor="start"
            fill="#666"
            transform="rotate(40)"
          >
            {label}
          </text>
        </g>
      )
    );
  }
}

const KPILineChart = ({
  classes,
  width = '470',
  data,
  dots = false,
  dataKeyA,
  dataKeyB,
  strokeA,
  strokeB,
  useLLY,
  format,
  useUsdAmount,
  dataPeriod,
}) => {
  const currency = useUsdAmount
    ? 'USD'
    : data.length > 0
      ? data[0].currency_code
      : null;

  return (
    <ResponsiveContainer width="inhirit" height="80%" className={classes.root}>
      <div>
        <LineChart
          width={width}
          height={250}
          data={data}
          margin={{ top: 25, right: 35, left: 6, bottom: 30 }}
        >
          <CartesianGrid strokeDasharray="2" />
          <XAxis
            type="category"
            dataKey="period_name"
            interval={0}
            tick={
              <CustomizedxAxisTick period={dataPeriod} length={data.length} />
            }
          />

          <YAxis
            tickLine={true}
            axisLine={true}
            allowDataOverflow="false"
            tickFormatter={value => axisDataFormater(value, currency, format)}
          />
          <Tooltip
            cursor={false}
            content={
              <CustomizedTooltip
                chartType="0"
                className="customizeTooltip"
                strokeA={strokeA}
                strokeB={strokeB}
                useLLY={useLLY}
              />
            }
          />
          <Line
            type="monotone"
            dataKey={dataKeyA}
            stroke={strokeA}
            dot={true}
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey={dataKeyB}
            stroke={strokeB}
            dot={true}
            strokeWidth={2}
          />
        </LineChart>
      </div>
    </ResponsiveContainer>
  );
};

export default withStyles(styles, { name: 'LriLineChart' })(KPILineChart);
