import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import BarChart from '../components/Charts/Bar';
import { ButtonTabs, ButtonTab } from '../components/ButtonTabs';
import { CardGrid, SalesCard } from '../components/Cards';
import { ErrorPageOrChildren } from '../components/Errors';
import { AscendingSortIcon, DescendingSortIcon } from '../components/Icons';
import Header from '../components/Header';
import HeaderDivider from '../components/HeaderDivider';
import LoadingBar from '../components/LoadingBar';
import MetricsSection from '../components/MetricsSection';
import { Panel } from '../components/Panel';
import ProductList from '../components/ProductList';
import SectionSpacer from '../components/SectionSpacer';
import TimeStampPanel from '../components/TimeStampPanel';
import useFullProductsData from '../data/hooks/useFullProductsData';
import { transformProductsInPanels } from '../data/utils/transforms';
import { LocationAndParamsShape } from '../location';
import ErrorBoundary from '../ErrorBoundary';
import { PageView, GTAG_EVENT } from '../gtag';
import { usePreferencesManager } from '../data/user-preferences';
import ProductsPerformance from '../components/ProductsPerformance';
import SalesUnitsSwitchList from '../components/SalesUnitsSwitch';

const styles = theme => ({
  barChart: {
    marginTop: theme.spacing(2),
  },
});
const PerformanceStyles = theme => ({
  sortIconContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    height: 45,
    '& .MuiToolbar-dense': {
      minHeight: '45px !important',
    },
  },
  iconHolder: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
});
const Accordion = withStyles(theme => ({
  root: {
    margin: '12px 0',
  },
  rounded: { borderRadius: '6px' },
}))(MuiAccordion);
const AccordionDetails = withStyles(theme => ({
  root: {
    display: 'block',
  },
  rounded: { borderRadius: '6px' },
}))(MuiAccordionDetails);
const AccordionSummary = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.headerCollapse,
    color: theme.palette.text.primary,
    minHeight: '32px',
    height: '32px',
    borderRadius: '6px',
  },
  content: {
    display: 'block',
    '&$expanded': {
      margin: '12px 0 !important',
    },
  },
  expanded: {
    minHeight: '32px !important',
  },
}))(MuiAccordionSummary);

const GenderCards = ({ data, useLLY }) => {
  return (
    <CardGrid>
      {Object.entries(data).map(([k, v]) => (
        <SalesCard
          key={k}
          topLabel={v.formattedSum}
          middleLabel={v.formattedPercentage}
          bottomLabel={v.genderLabel}
          color={k === 'men' || k === 'boys' ? 'primary' : 'secondary'}
          delta={v.dpy}
          useLLY={useLLY}
        />
      ))}
    </CardGrid>
  );
};

GenderCards.propTypes = {
  data: PropTypes.shape({
    formattedSum: PropTypes.string,
    formattedPercentage: PropTypes.string,
    genderLabel: PropTypes.string,
  }),
};

const ProductPanel = ({
  categoryItems,
  performanceType,
  performanceId,
  sortingOrder,
}) =>
  categoryItems.map((panel, index) => {
    const sortedCategoryItems =
      sortingOrder === 'asc'
        ? JSON.parse(JSON.stringify(panel.sections[performanceType]))
        : JSON.parse(JSON.stringify(panel.sections[performanceType])).reverse();
    return (
      <Panel
        title={panel.panelName}
        key={panel.panelName}
        collapsible
        initialCollapsed={index !== 0}
        customExpandIcons={[ExpandMoreIcon, ExpandLessIcon]}
      >
        <ProductList
          items={sortedCategoryItems}
          performanceType={performanceId}
        />
      </Panel>
    );
  });

const Stock = ({ classes, data, useLLY }) => {
  const [value, setValue] = useState('men');
  const [segmentValue, setSegmentValue] = useState('adults');
  useEffect(() => {
    if (segmentValue === 'adults') {
      setValue('men');
    }
    if (segmentValue === 'children') {
      setValue('boys');
    }
  }, [segmentValue]);
  return (
    <>
      <Header title="Gender Mix Sales" />
      <ButtonTabs
        onChange={(_, val) => {
          setSegmentValue(val);
          GTAG_EVENT({
            view: 'tab_view',
            category: 'Gender Mix Sales',
            label: val,
          });
        }}
        value={segmentValue}
      >
        <ButtonTab label="Adults" value="adults" />
        <ButtonTab label="Children" value="children" />
      </ButtonTabs>
      <GenderCards data={data?.totals[segmentValue]} useLLY={useLLY} />
      {data?.chart?.[segmentValue] && (
        <BarChart
          className={classes.barChart}
          data={data.chart[segmentValue]}
        />
      )}
      <SectionSpacer />
      <Header title="Sales & Stock Mix" />
      <ButtonTabs
        onChange={(_, val) => {
          setValue(val);
          GTAG_EVENT({
            view: 'tab_view',
            category: 'Sales & Stock Mix',
            label: val,
          });
        }}
        value={value}
      >
        <ButtonTab label="Men" value="men" />
        <ButtonTab label="Women" value="women" />
        <ButtonTab label="Boys" value="boys" />
        <ButtonTab label="Girls" value="girls" />
      </ButtonTabs>
      {data[value]?.panels.map(panel => (
        <Panel title={panel.panelName} key={panel.panelName} disabled>
          {panel.sections.map(section => (
            <MetricsSection
              key={section.sectionName ?? ''}
              section={section}
              useLLY={useLLY}
            />
          ))}
        </Panel>
      ))}
    </>
  );
};

const PerformanceCore = ({ classes, data }) => {
  // assuming the API returns both men and women so data is of format
  // data: {best_sellers: {}, worst_sellers {}, blanks: {}}
  const [categories, setCategories] = useState('best_sellers');
  const [expanded, setExpanded] = useState('men');
  const [sortingOrder, setSortingOrder] = useState('asc');

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const pm = usePreferencesManager();
  const performanceList = [
    { id: 'PC9', label: 'PC9 PERFORMANCE' },
    { id: 'PC5', label: 'PC5 PERFORMANCE' },
  ];

  const salesUnitsList = [
    { id: 'by_units', label: 'By Units' },
    { id: 'by_sales', label: 'By Sales' },
  ];

  const getPerformance = (items, pm) => {
    if (!items) return null;
    return items.find(x => x.id === pm.get('performance')) || items[0];
  };

  const getPerformanceType = (items, pm) => {
    if (!items) return null;
    return items.find(x => x.id === pm.get('performanceType')) || items[0];
  };

  const [selected, setSelected] = useState(() =>
    getPerformance(performanceList, pm)
  );

  const [selectedPerformanceType, setSelectedPerformanceType] = useState(() =>
    getPerformanceType(salesUnitsList, pm)
  );

  const performances = data[selected?.id];
  const performanceType = selectedPerformanceType.id;

  const handleSelect = item => {
    setSelected(item);
    pm.set('performance', item.id);
  };

  const handlePerformanceTypeSelect = item => {
    setSelectedPerformanceType(item);
    pm.set('performanceType', item.id);
  };

  const handleCategoriesChange = value => {
    if (categories === 'blanks') handlePerformanceTypeSelect(salesUnitsList[0]);
    setCategories(value);
    GTAG_EVENT({
      view: 'tab_view',
      category: 'PC9 Performance',
      label: value,
    });
  };

  useEffect(() => {
    if (categories === 'blanks')
      handlePerformanceTypeSelect({ id: 'by_sales', label: 'By Sales' });
    // eslint-disable-next-line
  }, [categories]);

  const productInPanels = transformProductsInPanels(performances[categories]);

  return (
    <>
      <SectionSpacer />
      <ProductsPerformance
        onSelect={handleSelect}
        selected={selected}
        performancesList={performanceList}
      />
      <div className={classes.sortIconContainer}>
        {categories !== 'blanks' && (
          <div className={classes.iconHolder}>
            <IconButton
              onClick={() => {
                setSortingOrder(sortingOrder === 'asc' ? 'desc' : 'asc');
              }}
            >
              {sortingOrder === 'asc' ? (
                <AscendingSortIcon />
              ) : (
                <DescendingSortIcon />
              )}
            </IconButton>
            <SalesUnitsSwitchList
              onSelect={handlePerformanceTypeSelect}
              selected={selectedPerformanceType}
              salesUnitsList={salesUnitsList}
            />
          </div>
        )}
      </div>
      <ButtonTabs
        onChange={(_, val) => handleCategoriesChange(val)}
        value={categories}
      >
        <ButtonTab label="Best Sellers" value="best_sellers" />
        <ButtonTab label="Worst Sellers" value="worst_sellers" />
        <ButtonTab label="Blanks" value="blanks" />
      </ButtonTabs>
      {productInPanels.map(category => (
        <React.Fragment key={category.id}>
          <Accordion
            expanded={expanded === category.id}
            onChange={handleChange(category.id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={category.id + '-content'}
              id={category.id + '-header'}
            >
              <HeaderDivider title={category.genderLabel} />
            </AccordionSummary>
            <AccordionDetails>
              <ProductPanel
                categoryItems={category.items}
                performanceType={performanceType}
                performanceId={selectedPerformanceType.id}
                sortingOrder={sortingOrder}
              />
            </AccordionDetails>
          </Accordion>
        </React.Fragment>
      ))}
    </>
  );
};

const Performance = withStyles(PerformanceStyles, {
  name: 'LriPerformanceCore',
})(PerformanceCore);

const ProductsCore = ({
  classes,
  period,
  location: locationAndParams,
  userSettings,
  showTimeStamp,
}) => {
  const locationId = locationAndParams.location.id;
  const { data, loading, error, refetch } = useFullProductsData(
    locationId,
    locationAndParams.params,
    period?.id,
    userSettings.useUsdAmount,
    userSettings.includeTaxes,
    userSettings.useLLY
  );

  if (loading) {
    return <LoadingBar />;
  }

  return (
    <PageView
      params={{
        period_id: period?.id,
        location_id: locationAndParams.location.name,
        channel: locationAndParams.params.channel,
      }}
    >
      <ErrorPageOrChildren
        message="Could not load sales data."
        errors={[error]}
        onClickRetry={refetch}
      >
        <>
          {data?.formattedTimeStamp && showTimeStamp && (
            <TimeStampPanel formattedValue={data.formattedTimeStamp} />
          )}
          {data?.stock && (
            <ErrorBoundary>
              <Stock
                classes={classes}
                data={data?.stock}
                useLLY={userSettings.useLLY}
              />
            </ErrorBoundary>
          )}
          {data?.products && (
            <ErrorBoundary>
              <Performance data={data?.products} />
            </ErrorBoundary>
          )}
        </>
      </ErrorPageOrChildren>
    </PageView>
  );
};

ProductsCore.propTypes = {
  period: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  location: LocationAndParamsShape.isRequired,
  userSettings: PropTypes.shape({
    useUsdAmount: PropTypes.bool.isRequired,
    includeTaxes: PropTypes.bool.isRequired,
  }),
};

const Products = React.memo(
  withStyles(styles, { name: 'LriProductsCore' })(ProductsCore),
  (prevProps, nextProps) =>
    prevProps.location === nextProps.location &&
    prevProps.period === nextProps.period &&
    prevProps.userSettings === nextProps.userSettings
);

export default Products;
