import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, CardContent } from '@material-ui/core';
import PerformancePill from './PerformancePill';
import { Text2 } from '../typography';
import { CardGrid, Card } from '../components/Cards';

const styles = theme => ({
  root: {
    '& > *': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& > *:last-child': {
      borderBottom: 'none',
    },
  },
  cardContent: { padding: 0, width: '100%' },
  metricRow: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1) + 2}px`,
  },
  valueAndDpyRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: 5,
    justifyContent: 'space-between',
  },
  pill: {
    whiteSpace: 'normal',
    minHeight: 24,
    height: 'unset',
    minWidth: 'unset',
  },
});

const RealTimeKPIList = ({ metrics, classes }) => {
  return (
    <div className={classes.root}>
      <CardGrid
        justify="flex-start"
        itemXs={6}
        itemSm={4}
        itemMd={4}
        itemLg={3}
      >
        {metrics.map(({ id, label, formattedValue, realtime }) => {
          if (realtime) {
            return (
              <Card
                key={id}
                color="tertiary"
                spacing={0.5}
                justify="flex-start"
              >
                <CardContent className={classes.cardContent}>
                  <div className={classes.metricRow}>
                    <Text2 gray component="p" style={{ fontSize: '1.5rem' }}>
                      {label}
                    </Text2>
                    <div className={classes.valueAndDpyRow}>
                      {realtime ? (
                        <Text2
                          bold
                          component="span"
                          style={{ fontSize: '1.5rem' }}
                        >
                          {formattedValue}
                        </Text2>
                      ) : (
                        <Text2
                          component="span"
                          style={{ fontSize: '1.5rem', opacity: 0.3 }}
                        >
                          Not available in real time
                        </Text2>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
            );
          } else {
            return null;
          }
        })}
      </CardGrid>
    </div>
  );
};

RealTimeKPIList.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      formattedValue: PropTypes.string.isRequired,
      realtime: PropTypes.bool.isRequired,
      dpy: PerformancePill.propTypes.delta,
    }).isRequired
  ),
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { name: 'LriRealTimeKPIList' })(
  RealTimeKPIList
);
